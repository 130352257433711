.ovv-countup-span {
    font-size: var(--h1);
    font-weight: 500;
}

.ovv-imageIcons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: var(--space);
}

.ovv-imageIcon {
    width: 50px;
    /* height: 0px; */
}

.ovv-countup-span {
    font-size: var(--h1);
    font-weight: 500;
}

.ovv-imageIcons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: var(--space);
}

.ovv-imageIcon {
    /* width: 40px; */
    /* height: 0px; */
}

.ovv-chart-detail {
    padding: 20px;  
    margin-left: 30px; 
}

.ovv-chart-detail p {
    color: rgb(102,102,102);  
    font-weight: 500; 
}

.ovv-chart-legend {
    display: flex;
    justify-content: flex-end;
}
.ovv-container {
    padding: 0 30px
}

.ovv-center-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ovv-dots-container {
    display: flex;
    width: 150px;
    margin-right: 30px;
    /* border: 1px solid red; */
    justify-content: space-between;
    margin-bottom: 20px;
}

.ovv-dots-content {
    width: 55px;
    display: flex;
    justify-content: space-between;
}

.ovv-legend-text {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    padding:0;
    line-height: 1; */
    color: gray;
    font-weight: 500;

}

