body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --blue: #1e90ff;
  --white: #fff;
  --primary: rgb(37, 147, 252);
  --illustration: rgb(240, 242, 245);
  --verticalMargin: 20px;
  --space: 20px;
  --smallSpace: 10px;
  --tinySpace: 5px;
  --h1: 24px;
  --h2: 20px;
  --h3: 18px;
  --h4: 18px;
}

.space-between {
  display: flex;
  flex-direction: row;
}
