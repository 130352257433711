.logo {
    text-align: center;
    cursor: pointer;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo img {
    width: 40px;
    margin-right: 8px;
  }
  
  .logo span {
    vertical-align: text-bottom;
    font-size: 16px;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 700;
    /* color: var(--primary); */
  }
  
  /* Card Section Styles */
  .site-page-header {
    font-size: 30px;;
  }